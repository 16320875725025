<template>
  <div class="ポガーズ">
    <b-container>
      <b-row>
        <b-col cols="12">
          <div class="text-center">
            <h1>Login...</h1>
            <div class="Maintenance" v-if="maintenance == true">
              <h1>Yukiko is busy fighting Shadows!</h1>
              <p>
                This part of the website is under maintenance, and not
                availiable at the moment.<br />
                This page will verify the status of the maintenance and redirect
                you to discord's login once the maintenance done, so you can
                either wait or try again later. <br />
                you can also consult our
                <a
                  href="http://https://yukikoapp.statuspage.io"
                  target="_blank"
                  rel="noopener noreferrer"
                  >Status page</a
                >
                to know when the server will be back online.
              </p>
              <router-link to="/">Return to home page.</router-link> ●
              <a
                href="http://https://yukikoapp.statuspage.io"
                target="_blank"
                rel="noopener noreferrer"
                >Status page</a
              >
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      maintenance: true,
      maintenanceLoading: true,
    };
  },
  beforeMount() {
    axios
      .get(`${process.env.VUE_APP_URI}/api/agi/maintenance/login`, {
        withCredentials: true,
      })
      .then((res) => {
        this.maintenance = true;
        this.maintenanceLoading = true;
        return res;
      });
  },
  mounted() {
    setTimeout(() => {
      setInterval(() => {
        if (this.maintenance == false) {
          window.location.href = `${process.env.VUE_APP_URI}/api/agi/auth/`;
        } else {
          return;
        }
      }, 1000);
    }, 1000);
  },
};
</script>
