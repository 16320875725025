<template>
  <div>
    <b-container>
      <b-row>
        <b-col cols="12">
          <h3 class="text-center" :v-if="error" style="color: RED">
            {{ message }}
          </h3>
          <b-col cols="12" class="mb-4"
            ><b-img class="text-center" fluid :src="cardLink"></b-img
          ></b-col>
          <b-form @submit="submitFile" @reset="onReset" class="mt-4">
            <b-form-file
              v-model="file1"
              :state="Boolean(file1)"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
              accept="image/jpeg, image/jpg, image/png"
              type="file"
              required
            ></b-form-file>
            <b-button type="submit" variant="outline-primary" class="mt-2"
              >{{$t('logeduser.profile.cards.submit')}}</b-button
            >
            <b-button type="reset" variant="outline-danger" class="mt-2 ml-2"
              >{{$t('logeduser.profile.cards.reset')}}</b-button
            >
          </b-form>
          <div class="mt-3">
            <i
              >{{$t('logeduser.profile.cards.info1')}} <br />
              {{$t('logeduser.profile.cards.info2')}} <br />
              {{$t('logeduser.profile.cards.size')}}
            </i>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
/* eslint-disable no-unreachable */
import axios from "axios";
export default {
  data() {
    return {
      file1: null,
      cardLink: "",
      error: "",
      message: ""
    };
  },
  beforeMount() {
    axios
      .get(`${process.env.VUE_APP_URI}/api/agi/user/profile/card`, {
        withCredentials: true
      })
      .then(res => {
        this.cardLink = res.data.card;
      });
  },
  methods: {
    submitFile(event) {
      event.preventDefault();
      const formData = new FormData();
      formData.append("file", this.file1);
      axios
        .post(
          `${process.env.VUE_APP_URI}/api/agi/user/profile/card`,
          formData,
          { withCredentials: true },
          {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          }
        )
        .then(res => {
          const data = res.data;
          console.log(data);
          this.cardLink = data.cardLink;
          this.message =
            "New card has been saved! Allow up to 5mn for the changes to take effect.";
          if (res.data.error == 500) {
            (this.error = res.data.error), (this.message = res.data.message);
          }
        });
    },
    onReset(event) {
      event.preventDefault();
      this.file1 = "https://cdn.asthriona.com/DefaultYukikocard.jpg";
      axios
        .post(
          `${process.env.VUE_APP_URI}/api/agi/user/profile/card/reset`,
          {},
          { withCredentials: true }
        )
        .then(res => {
          (this.cardLink = res.data.cardLink),
            (this.error = res.data.error),
            (this.message = res.data.message);
        })
        .catch(error => {
          console.log(error);
          this.message =
            "Oops! Our server is currently fighting shadows... Please try again later. (error 500)";
        });
    }
  }
};
</script>