<template>
  <div>
    <b-container>
      <b-row>
        <b-col cols="12">
          <div class="alert text-center" v-if="saved">
            <b-alert :variant="saved.error" show>{{ saved.message }}</b-alert>
          </div>
        </b-col>
        <b-col cols="12" class="text-center">
          <h1>Channels Settings</h1>
        </b-col>
        <b-col>
          <h4>{{$t('logeduser.guilds.guildDash.channel.logs')}} </h4>
          <p>
            {{$t('logeduser.guilds.guildDash.channel.logs_desc')}} <br>
            <small class="red" >Message Logging has been disabled due to new Discord restrictions for users privacy.</small> <br>
            <small>This feature will come back once the audit log feature will be implemented.</small>
          </p>
          <b-form @submit="onSubmitLog">
            <b-form-select
              vartiant="dark"
              v-model="selectedLog"
              selected="None"
              :options="channels"
              disabled
            ></b-form-select>
            <b-button type="submit" variant="outline-danger" class="mt-2"
              disabled>{{$t('logeduser.guilds.guildDash.utility.submit')}}</b-button
            >
          </b-form>

          <h4 class="mt-4">{{$t('logeduser.guilds.guildDash.channel.welcome')}} </h4>
          <p>
            {{$t('logeduser.guilds.guildDash.channel.welcome_desc')}} 
          </p>
          <b-form @submit="onSubmitWelcome">
            <b-form-select
              vartiant="dark"
              v-model="selectedWelcome"
              :options="channels"
            ></b-form-select>
            <b-button type="submit" variant="outline-primary" class="mt-2"
              >{{$t('logeduser.guilds.guildDash.utility.submit')}}</b-button
            >
          </b-form>
<small>{{$t('logeduser.guilds.guildDash.channel.disabled')}}</small>
          <h4 class="mt-4">{{$t('logeduser.guilds.guildDash.channel.hallOfFame')}} </h4>
          <p>
            {{$t('logeduser.guilds.guildDash.channel.hallOfFame_desc')}} 
          </p>
          <b-form @submit="onSubmitRank">
            <b-form-select
              vartiant="dark"
              v-model="selectedRank"
              :options="channels"
            ></b-form-select>
            <b-button type="submit" variant="outline-primary" class="mt-2"
              >{{$t('logeduser.guilds.guildDash.utility.submit')}}</b-button
            >
          </b-form>
          <small>if "Disabled" is selected for "Hall of Fame", the bot will send the card in the same channel as the last users message. <br>
          to disable that function, please un-tick "Rank card" in  the "Utility" tab</small>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      defaultChannels: "",
      channels: [],
      selectedLog: "",
      selectedWelcome: "",
      selectedRank: "",
      saved: {
        error: "",
        message: "",
      },
    };
  },
  mounted() {
    axios
      .get(
        `${process.env.VUE_APP_URI}/api/agi/dashboard/guild/${this.$route.params.guildId}/config/channels`,
        { withCredentials: true }
      )
      .then((res) => {
        this.channels = res.data.GuildChannels.channelsList;
        this.channels.push({ value: "None", text: "Disabled" });
        this.defaultChannels = res.data.default;
        this.selectedLog = res.data.Config.log;
        this.selectedWelcome = res.data.Config.welcome;
        this.selectedRank = res.data.Config.rank
      });
  },
  methods: {
    onSubmitLog(event) {
      event.preventDefault();
      axios
        .post(
          `${process.env.VUE_APP_URI}/api/agi/dashboard/guild/${this.$route.params.guildId}/config/channels`,
          {
            logChan: this.selectedLog,
          },
          { withCredentials: true }
        )
        .then((res) => {
          const data = res.data;
          if (data.message == "Saved!") {
            (this.saved.error = "success"),
              (this.saved.message = `Log channel saved.`);
          } else {
            this.saved.error = "danger";
            this.saved.message = "Oops! An error happened!";
          }
        });
    },
    onSubmitWelcome(event) {
      event.preventDefault();
      axios.post(
        `${process.env.VUE_APP_URI}/api/agi/dashboard/guild/${this.$route.params.guildId}/config/channels`,
        {
          welcomeChan: this.selectedWelcome,
        },
        { withCredentials: true }
      )
      .then((res) => {
          const data = res.data;
          if (data.message == "Saved!") {
            (this.saved.error = "success"),
              (this.saved.message = `Welcome channel saved.`);
          } else {
            this.saved.error = "danger";
            this.saved.message = "Oops! An error happened!";
          }
        });
    },
    onSubmitRank(event) {
      event.preventDefault();
      axios.post(
        `${process.env.VUE_APP_URI}/api/agi/dashboard/guild/${this.$route.params.guildId}/config/channels`,
        {
          rankChan: this.selectedRank,
        },
        { withCredentials: true }
      )
      .then((res) => {
          const data = res.data;
          if (data.message == "Saved!") {
            (this.saved.error = "success"),
              (this.saved.message = `Rank channel saved.`);
          } else {
            this.saved.error = "danger";
            this.saved.message = "Oops! An error happened!";
          }
        });
    },
  },
};
</script>

<style scoped>
.disabled {
  cursor: not-allowed;
}
.red {
  color: red !important;
}
</style>