<template>
  <div>
    <div class="Blog_Head">
      <h2>Yukiko Dev Team's News</h2>
      </div>
      <hr>
    <b-container>
      <b-row>
        <b-col cols="12">
          <h1 class="mt-4">{{ post.title }}</h1>
          <small> {{ post.createdAt | moment("MMMM Do YYYY, h:mm a") }} </small>
          <hr />
          <div class="content" v-html="post.SanitasedContent"></div>
        </b-col>
      </b-row>
      <hr />
      <div class="Author">
        <div class="authorInfo">
          <div class="authorInfo__avatar">
            <img :src="author.avatar" alt="" />
          </div>
          <div class="authorInfo__name">
            <h3>
              {{ author.name }}
              <b-icon v-if="author.isAdmin" icon="patch-check"></b-icon>
            </h3>
          </div>
          <div class="authorInfo__bio">
            <p>{{ author.bio || "Yukiko Dev Team's Staff" }}</p>
          </div>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "PostShow",
  data() {
    return {
      post: "",
      author: "",
    };
  },
  async beforeMount() {
    axios
      .get(
        `${process.env.VUE_APP_URI}/api/agi/blog/post/${this.$route.params.postId}`
      )
      .then((res) => {
        this.post = res.data.post;
        axios
          .get(
            `${process.env.VUE_APP_URI}/api/agi/blog/author/${this.post.authorId}`
          )
          .then((res) => {
            const data = res.data;
            let bio;
            if (data.id == "186195458182479874") {
              bio = "Stellar Corporation's Founder & Yukiko Bot Lead Developper";
            } else if (data.id == "635422418424299521") {
              bio = "Stellar Corporation's Web Developper from Japan (She/Her)";
            }
            this.author = {
              name: data.username,
              avatar: `https://cdn.discordapp.com/avatars/${data.id}/${data.avatar}`,
              bio: bio,
              isAdmin: true,
            };
          });
      })
      .catch((err) => {
        console.log(err);
      });
  },
};
</script>

<style>
.Author {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.authorInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.authorInfo__avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 20px;
}
.authorInfo__avatar img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.authorInfo__name {
  font-size: 1.5em;
  margin-bottom: 20px;
}
.authorInfo__bio {
  font-size: 1em;
}
.Blog_Head h2 {
  font-size: 2em;
  font-weight: 100 !important;
  margin-left: 10%;
}
.content img {
  max-width: 100%;
  height: auto;
}
@media screen and (max-width: 1200px) {
  .Blog_Head h2 {
    visibility: hidden;
  }
}
</style>