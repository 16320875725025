<template>
    <div class="ポガーズ">
        <h1>Refreshing...</h1>
        <div class="Maintenance" v-if="maintenance == true">
              <h1>Yukiko is busy fighting Shadows!</h1>
              <p>
                This part of the website is under maintenance, and not
                availiable at the moment.<br />
                This page will verify the status of the maintenance and redirect
                you to discord's login once the maintenance done, so you can
                either wait or try again later. <br />
                you can also consult our
                <a
                  href="http://https://yukikoapp.statuspage.io"
                  target="_blank"
                  rel="noopener noreferrer"
                  >Status page</a
                >
                to know when the server will be back online.
              </p>
              <router-link to="/">Return to home page.</router-link> ●
              <a
                href="http://https://yukikoapp.statuspage.io"
                target="_blank"
                rel="noopener noreferrer"
                >Status page</a
              >
            </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            maintenance: "false",
            maintenanceLoading: false,
        };
    },
    mounted(){
      window.location.href = `${process.env.VUE_APP_URI}/api/agi/auth/`;
    }
}
</script>