<template>
  <div class="ポガーズ text-center">
    <h1>Getting Discord information</h1>

    <!-- Maintenance View -->
    <div class="Maintenance" v-if="maintenance == true">
      <h1>Yukiko is busy fighting Shadows!</h1>
      <p>
        This part of the website is under maintenance, and not
        availiable at the moment.<br />
        This page will verify the status of the maintenance and redirect
        you to discord's login once the maintenance done, so you can
        either wait or try again later. <br />
        you can also consult our
        <a href="http://https://yukikoapp.statuspage.io" target="_blank" rel="noopener noreferrer">Status page</a>
        to know when the server will be back online.
      </p>
      <router-link to="/">Return to home page.</router-link> ●
      <a href="http://https://yukikoapp.statuspage.io" target="_blank" rel="noopener noreferrer">Status page</a>
    </div>
  </div>
</template>

<script>
// import axios from "axios";
export default {
  name: "bot-redirect",
  data() {
    return {
      loading: false,
      maintenance: true,
      // maintenanceLoading: true
    };
  },
  mounted() {
    setTimeout(() => {
      this.loading == false;
    }, 1000)
    if (this.maintenance == false) {
      window.location.href = `https://discord.com/api/oauth2/authorize?client_id=${process.env.VUE_APP_CLIENT_ID}&permissions=8&redirect_uri=${process.env.VUE_APP_ADDBOT}&response_type=code&scope=bot%20applications.commands&guild_id=${this.$route.params.guildId}`
    }
  }
}
</script>
