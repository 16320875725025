<template>
  <div class="RiseChan youdumdum">
    <b-container>
      <b-row>
        <b-col cols="12" class="guild-info">
          <b-img
            v-bind="mainProps"
            fluid
            rounded="circle"
            :src="`https://cdn.discordapp.com/icons/${guild.id}/${guild.icon}.webp?size=2048`"
            alt=""
          >
          </b-img>
          <h1>{{ guild.name }}'s leaderboard</h1>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <table class="table table-dark table-striped">
            <thead>
              <th>#</th>
              <th>Username</th>
              <th>Level</th>
              <th>XP</th>
              <th>Messages</th>
            </thead>
            <tbody>
              <tr v-for="(user, index) in users" :key="user.did">
                <td>{{ index + 1 }}</td>
                <td>
                  <img
                    :src="user.avatarURL"
                    class="rounded-circle"
                    width="32"
                    height="32"
                  />
                  {{ user.username }}
                </td>
                <td>{{ user.level }}</td>
                <td>{{ user.xp }}</td>
                <td>{{ users.message }}</td>
              </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "LeaderboardsGuildsLevels",
  data() {
    return {
      mainProps: {
        blank: false,
        blankColor: "#777",
        width: 256,
        height: 256,
        class: "m1",
      },
      users: [],
      guild: "",
      field: [
        {
          key: "username",
          label: "Username",
        },
        {
          key: "avatar",
          label: " ",
        },
        {
          key: "level",
          label: "Level",
        },
        {
          key: "xp",
          label: "XP",
        },
        {
          key: "message",
          label: "Nb Message",
        },
      ],
    };
  },
  mounted() {
    axios
      .get(
        `${process.env.VUE_APP_URI}/api/agi/user/guild/${this.$route.params.guildId}/experiance`
      )
      .then((res) => {
        console.log(res.data.users);
        const data = res.data.users;
        this.guild = res.data.guild;
        this.users = data;
        console.log(this.users);
      });
  },
};
</script>

<style scoped>
.guild-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}
table {
  width: 100% !important;
  border-radius: 0.5rem;
  box-shadow: 0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.5);
  transition: box-shadow 1.5s;
}
table:hover {
  box-shadow: 1.5rem 1.5rem 1rem rgba(0, 0, 0, 0.5);
  transition: box-shadow 1.5s;
}
.table,
tbody,
tr,
td,
th {
  background-color: rgba(0, 0, 0, 0) !important;
}
</style>
