<template>
  <div>
    <hr>
            <b-col cols="12" class="mt-4">
          <h4 class="text-center mt-4">
            <ul>
              <li @click="comp = 'profile'">{{$t('logeduser.profile.links.profile')}}</li>
              |
              <li @click="comp = 'card'">{{$t('logeduser.profile.links.cards')}}</li>
            </ul>
          </h4>
        </b-col>
        <Profile v-if="comp == 'profile'" />
      <Card v-if="comp == 'card'" />
  </div>
</template>

<script>
import Profile from "../../../components/profile/profile";
import Card from "../../../components/profile/card";
import axios from "axios";
export default {
  name: "ProfileView",
  data() {
    return {
      comp: "profile"
    };
  },
  components: {
    Profile,
    Card
  },
    beforeMount() {
    axios
      .get(`${process.env.VUE_APP_URI}/api/agi/user/profile`, {
        withCredentials: true,
      })
      .then((res) => {
        const data = res.data;
        if (data.isban == true) return this.$router.push("/banned");
      });
  }
};
</script>

<style scoped>
li {
  list-style-type: none;
  display: inline;
  text-align: center;
  color: #007bff;
}
li:hover {
  border-bottom: 2px solid #ffe52c;
  cursor: pointer;
}
</style>