<template>
  <div>
    <b-row>
      <b-col cols="12" class="mt-4">
        <h2 class="text-center">Utility Setings</h2>
        <b-col cols="12" class="text-center mt-4 mb-4">
          <b-alert :variant="alert" v-if="message" show>
            {{ message }}
          </b-alert>
        </b-col>
        <b-form @submit="onSubmit">
          <table class="table">
            <tbody>
              <tr>
                <th>{{$t('logeduser.guilds.guildDash.utility.leveling')}}</th>
                <td>
                  <b-form-checkbox
                    id="leveling"
                    v-model="form.level"
                    name="leveling"
                    value="true"
                    unchecked-value="false"
                  >
                  </b-form-checkbox>
                </td>
              </tr>
              <tr>
                <th>{{$t('logeduser.guilds.guildDash.utility.welcone')}}</th>
                <td>
                  <b-form-checkbox
                    id="welcome"
                    v-model="form.welcome"
                    name="welcome"
                    value="true"
                    unchecked-value="false"
                  >
                  </b-form-checkbox>
                </td>
              </tr>
              <tr>
                <th>{{$t('logeduser.guilds.guildDash.utility.farewell')}}</th>
                <td>
                  <b-form-checkbox
                    id="farewell"
                    v-model="form.farewell"
                    name="farewell"
                    value="true"
                    unchecked-value="false"
                  >
                  </b-form-checkbox>
                </td>
              </tr>
              <tr>
                <th>{{$t('logeduser.guilds.guildDash.utility.rankcard')}}</th>
                <td>
                  <b-form-checkbox
                    id="rankcard"
                    v-model="form.rankcard"
                    name="rankcard"
                    value="true"
                    unchecked-value="false"
                  >
                  </b-form-checkbox>
                </td>
              </tr>
              <tr class="disabled">
                <th>User Count</th>
                <td>
                  <b-form-checkbox
                    id="usercount"
                    v-model="form.usercount"
                    name="usercount"
                    value="false"
                    unchecked-value="false"
                    class="disabled"
                  disabled>
                  </b-form-checkbox>
                </td>
              </tr>
            </tbody>
          </table>
          <b-button type="submit" variant="primary" class="text-left"
            >{{$t('logeduser.guilds.guildDash.utility.submit')}}</b-button
          >
        </b-form>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from "axios";
// import moment from "moment"
export default {
  name: "GuildUtilityComp",
  data() {
    return {
      autorized: "",
      channels: "",
      alert: "",
      message: "",
      form: {
        level: "",
        musicbot: false,
        welcome: "",
        farewell: "",
        usercount: "",
        rankcard: "",
        playerStay: false,
      },
    };
  },
  mounted() {
    axios
      .get(
        `${process.env.VUE_APP_URI}/api/agi/dashboard/guild/${this.$route.params.guildId}/config`,
        { withCredentials: true }
      )
      .then((res) => {
        let conf = res.data;
        if(conf.message == "No!"){
          this.alert = "danger";
          return this.autorized = false;
        }
        this.autorized = true
        this.form = {
          level: conf.level,
          musicbot: false,
          welcome: conf.welcome,
          farewell: conf.farewell,
          usercount: conf.usercount,
          rankcard: conf.rankcard,
          logChannel: conf.logChannel,
          playerStay: false,
        };
      });
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      axios
        .post(
          `${process.env.VUE_APP_URI}/api/agi/dashboard/guild/${this.$route.params.guildId}/config`,
          {
            level: this.form.level,
            musicbot: false,
            welcome: this.form.welcome,
            farewell: this.form.farewell,
            usercount: this.form.usercount,
            rankcard: this.form.rankcard,
            playerStay: false,
          }, {
          withCredentials: true
        })
        .then((res) => {
          const status = res.data.status;
          if (res.data.status == 200) {
            this.alert = "success";
            this.message = res.data.message;
          } else if (res.data.error == 401) {
            this.alert = "danger";
            this.message = res.data.message;
          }else if (status == 401) {
            this.alert = "danger";
            this.message = "You are not allowed to change the configuration of this guild.";
          } else {
            this.alert = "danger";
            this.message =
              "500 - Seems like there is an issue, please try again later.";
          }
        });
    },
  },
};
</script>

<style scoped>
.b-avatar img {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
}
td {
  text-align: right;
}
th {
  text-align: left;
}
.table {
  border-spacing: 5px;
  border-collapse: collapse;
  color: white;
}
tr:nth-child(even) {
  background-color: #1a1b1f;
}
small {
  color: darkgray;
}
.disabled{
  cursor: not-allowed;
  color: gray;
}
</style>