<template>
  <div class="Yukiko-Router">
    <div class="page-wrap">
      <router-view />
    </div>
    <div class="footer">
      <TheFooter />
    </div>
  </div>
</template>

<script>
import TheFooter from "./components/TheFooter";
export default {
  name: "App",
  data() {
    return {
      anounce: "",
      maintenance: false,
      announcedClosed: false
    };
  },
  components: {
    TheFooter,
  },
};
</script>

<style>
html body {
  background-color: #212226;
  color: #ccc;
}

#app {
  color: #ccc;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #007bff;
  text-decoration: none;
}

#nav a.router-link-exact-active {
  color: #007bff;
  text-decoration: underline;
}

html,
body {
  height: 100%;
}

.anounce {
  background-color: rgb(190, 0, 0);
  color: #fff !important;
  margin: 10px;
  padding-bottom: 1px;
}

.anounce a {
  color: rgb(0, 255, 255) !important;
  /* text decoration */
  text-decoration: dotted underline;
}

#adsbox {
  display: none;
}

.maintenance {
  font-size: 1.25rem;
  font-weight: 300;
}

.rouded {
  border-radius: 10px !important;
}

/* TODO: FIX PAGE WRAP */
/* .page-wrap {
  min-height: 100%;
  margin-bottom: -142px; 
}
.page-wrap:after {
  content: "";
  display: block;
}
.site-footer, .page-wrap:after {
  height: 142px; 
} */
</style>
