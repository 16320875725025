<template>
  <div class="Banned">
    <b-container v-if="isban == true">
      <b-row>
        <b-col cols="12" class="text-center mt-4">
          <b-avatar variant="info" :src="avatar" size="10rem"></b-avatar>
          <h3 class="mt-4">{{ username }}</h3>
        </b-col>
        <b-col cols="12" class="text-center mt-4">
          <h1>Your right to access Yukiko has been revoked.</h1>
          <p>
            Your account was associated with a Terms of Service violation. As a
            result we have issued you a suspension from using all
            Yukiko/Yukiko DevTeam's services. For more information please read
            <router-link to="/tos">our ToS</router-link> or send us an Email at
            <a href="mailto:contact@Yukiko.app">contact@Yukiko.app</a> with your
            <b>Discord ID</b>. We will reply with your information about why we decide
            to revoke your right to use Yukiko. if you want to delete your data
            from our database immediately please notify us to do so in your
            email. if your think that is an error, we would be more than happy
            to Talk about it with you and reinstate your account, if we come to the
            conclusion that is indeed an error. if we are unable to come to this
            conclusion, or if you don't send any emails within 30 days, your
            data will be automatically deleted. This include your Levels in <b>ANY</b>
            guilds where Yukiko is present, Rank cards, and IDs. this ban result in
            Yukiko immediatly ignoring you.
          </p>
          <hr class="mt-4">
            <div class="baninfo text-left">
              <h3>Ban information: </h3>
            <ul>
            <li>Username: {{username}}</li>
            <li>User ID: {{userId}}</li>
            <li>Status: Banned.</li>
            <li>Reason: {{reason}}</li>
            <li>Expire: {{expire |  moment("Do MMMM YYYY, h:mm A")}} ( {{expireFormat}} )</li>
            <li>banned By: {{given}} </li>
            </ul>
            </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
export default {
  data() {
    return {
      username: "",
      avatar: "",
      userId: "",
      isban: true,
      reason: "",
      given: "",
      expire: "",
      expireFormat: ""
    };
  },
  beforeMount() {
    axios
      .get(`${process.env.VUE_APP_URI}/api/agi/user/profile`, {
        withCredentials: true,
      })
      .then((res) => {
        const data = res.data;
        if (data.isban == false || !data.isban) {
          this.isban = false;
          this.$router.push("/");
        } else {
          this.isban = true;
          this.username = data.username;
          this.avatar = data.avatar;
          this.userId = data.userID;
          this.reason = data.reason;
          this.given = data.given;
          this.expire = data.expire;
          this.expireFormat = moment(data.expire).fromNow()

        }
      });
  },
};
</script>