<template>
  <div class="アッシュをファック！">
    <b-container v-if="message == 'not loged in.'">
      <b-row>
        <b-col cols="12">
          <div class="text-center" style="background-color: #a83632">
            <h1>Yikes! You are not logged in...</h1>
            <p>You need to login to access this page.</p>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <b-container>
      <div class="無許可" v-if="autorized == false">
        <center>
          <h1>YOU ARE NOT ALLOWED TO ACCES THIS GUILD! D:</h1>
          <img src="https://cdn.yukiko.app/web/nope.gif" alt="" />
        </center>
      </div>
    </b-container>
    <div class="無許可" v-if="autorized === true">
      <b-container v-if="notIn == true">
        <b-row>
          <b-col>
            <router-link to="/dashboard"
              ><b-icon icon="arrow-left-circle-fill"></b-icon> Back to the list
              of guilds</router-link
            >
            <h1 class="mt-4">It seems like Yukiko is not present in this guild!</h1>
            <p>
              Click the button below to add it. <br />
              If you have already added Yukiko to your guild, try sending a message in a channel to initialize your guild in our database. <br />
              If this message is still showing up after sending a message, please contact us at
              <a href="mailto:contact@yukiko.app">Contact@Yukiko.app</a>  <br />
              
              or via our 
              <a
                href="https://discord.gg/eG6jyTH"
                target="_blank"
                rel="noopener noreferrer"
                >Discord support server</a
              >
            </p>
            <div class="text-center">
              <router-link :to="'/addbot/' + this.$route.params.guildId"
                ><b-button variant="outline-primary"
                  >Add Yukiko</b-button
                ></router-link
              >&nbsp;
              <a href="mailto:contact@yukiko.app"
                ><b-button variant="outline-primary"
                  >Send us an email.</b-button
                ></a
              >
              &nbsp;
              <a
                href="https://discord.gg/eG6jyTH"
                target="_blank"
                rel="noopener noreferrer"
                ><b-button variant="outline-primary"
                  >Support server</b-button
                ></a
              >
              &nbsp;
            </div>
          </b-col>
        </b-row>
      </b-container>
      <b-container v-else-if="message == 'error500'">
        <b-row>
          <b-col>
            <h1>yikes! Seems like Yukiko is fighting shadow at the moment.</h1>
            <p>
              <span>We are sorry but it seems like our server are busy fighting Shadow at the moment. <br />  by this fact they can't process your request at this time. <br /> The Yukiko Dev Team is working on this issue, please check our Twitter (@YukikoApp) or status page</span>
              <a
                href="https://twitter.com/YukikoApp"
                target="_blank"
                rel="noopener noreferrer"
                >Twitter @YukikoApp</a
              >
              To know when they'll come back from their fight.
            </p>
            <pre>
            Yukiko Gateway Error 500 <br>
            report: {{ Math.round(Math.random()* 50000) }}
          </pre>
          </b-col>
        </b-row>
      </b-container>
      <b-container v-else-if="banned == true">
        <b-row>
          <b-col class="text-center mt-4">
            <b-avatar
                  variant="info"
                  :src="guildIcon"
                  size="10rem"
                ></b-avatar>
                <p>{{guildName}} | {{guildId}}</p>
            <h1>{{ guildName }} </h1>
            <p>
              {{$t('logeduser.guilds.guildDash.GuildBan.body1')}} <b>"{{guildName}}"</b> 
              {{$t('logeduser.guilds.guildDash.GuildBan.body2')}}
            </p>
            <p class="text-left">
              <b>{{$t('logeduser.guilds.guildDash.GuildBan.reason')}}:</b> {{reason}} <br>
              <b>{{$t('logeduser.guilds.guildDash.GuildBan.expire')}}:</b> {{expire | moment("Do MMMM YYYY, h:mm A")}} ({{expireFormat}}) <br>
              <b>{{$t('logeduser.guilds.guildDash.GuildBan.Staff')}}:</b> {{staff}} <br>
            </p>

          </b-col>
        </b-row>
      </b-container>
      <b-container v-else>
        <b-row>
          <b-col cols="12">
            <div class="text-center">
              <h1>Info: This section is being rebuilt!</h1>
              <p>We know the website might be a bit difficult to use at the moment, we are working on some update to make this section better!</p>
              <b-img :src="guildIcon" rounded="circle"></b-img>
              <h4 class="mt-4">{{ guildName }} / {{ guildId }}</h4>
            </div>
          </b-col>
          <b-col cols="12" class="text-center">
            <h4>
              <ul>
                <li @click="comp = 'utility'">{{$t('logeduser.guilds.guildDash.utility-btn')}}</li>
                |
                <li @click="comp = 'channel'">{{$t('logeduser.guilds.guildDash.channels-btn')}}</li>
                |
                <li @click="comp = 'roles'">{{$t('logeduser.guilds.guildDash.roles-btn')}}</li>
                <!-- |
                <li @click="comp = 'level'" class="disabled">Leveling</li>
                |
                <li @click="comp = 'welcome'" class="disabled">Welcome</li> -->
                |
                <li @click="comp = 'logs'">Logs</li>
              </ul>
            </h4>
          </b-col>
        </b-row>

        <router-link to="/dashboard"
          ><b-icon icon="arrow-left-circle-fill"></b-icon>{{$t('logeduser.guilds.guildDash.backList')}}</router-link
        >
        <GuildChannelComp v-if="comp == 'channel'" />
        <GuildRolesComp v-if="comp == 'roles'" />
        <GuildUtilityComp :guildName="guildName" v-if="comp == 'utility'" />
        <GuildLevelComp v-if="comp == 'level'" />
        <GuildWelcomeComp v-if="comp == 'welcome'" />
        <GuildLogsComp v-if="comp == 'logs'" />
        <!-- <GuildPremiumComp :guildId="guildId" :guildName="guildName" :premium="guildPremium" /> -->
      </b-container>
    </div>
  </div>
</template>


<script>
// @ is an alias to /src
import GuildChannelComp from "../../../components/guilds/GuildChannel";
import GuildRolesComp from "../../../components/guilds/GuildRoles";
import GuildUtilityComp from "../../../components/guilds/GuildUtility";
import GuildLevelComp from "../../../components/guilds/GuildLeveling";
import GuildWelcomeComp from "../../../components/guilds/GuildWelcome";
import GuildLogsComp from "../../../components/guilds/GuildLogs";
// import GuildPremiumComp from "../../../components/guilds/premium.vue";
import axios from "axios";
export default {
  name: "Home",
  components: {
    GuildChannelComp,
    GuildRolesComp,
    GuildUtilityComp,
    GuildLevelComp,
    // GuildPremiumComp,
    GuildWelcomeComp,
    GuildLogsComp
  },
  data() {
    return {
      comp: "utility",
      notIn: "",
      autorized: true,
      banned: false,
      reason: "",
      expire: "",
      expireFormat: "",
      staff: "",
      message: "",
      guildId: "",
      guildIcon: "",
      guildName: "",
      guildPremium: {
        status: "",
        expire: ""
      },
    };
  },
  beforeMount() {
    // Get user data
    axios
      .get(`${process.env.VUE_APP_URI}/api/agi/user/profile`, {
        withCredentials: true,
      })
      .then((res) => {
        const data = res.data;
        if (data.isban == true) return this.$router.push("/banned");
      });
      // Check if yukiko is in the guild. or if user is logged in and their permissions
    axios
      .get(
        `${process.env.VUE_APP_URI}/api/agi/dashboard/guild/${this.$route.params.guildId}/bot`,
        { withCredentials: true }
      )
      .then((res) => {
        const data = res.data;
        this.guildId = data.guildId;
        this.guildIcon = data.guildIcon;
        this.guildName = data.guildName;
        this.guildPremium = {
          status: 0,
          expire: 0
        }
          // else check if the guild is initialized in the database
        }) 
        .catch ((error) => {
          if (error.response.status == 403) {
            const resErr = error.response.data.error
            const data = error.response.data;
            if (error.response.data.error == 1) {
              this.guildId = data.banInfo.guildId;
              this.guildIcon = data.banInfo.guildIcon;
              this.guildName = data.banInfo.guildName;
            }
            if(resErr == 2 || resErr == 3) return this.autorized = false;
          }
          if(error.response.status == 404) {
            console.log("not in")
            return this.notIn = true
          }
          // ERRORS
          // any 500 errors from the server.
          if (error.response.status == 500) return (this.message = "error500");
        })
      // check if guild is initialized.
      axios.get(`${process.env.VUE_APP_URI}/api/agi/dashboard/guild/${this.$route.params.guildId}/config/check`, {withCredentials: true})
      .then((res) => {
        console.log(res.data);
      })
  },
  mounted() {
    document.title = `Yukiko - Loading important stuff...`;
    setTimeout(() => {
      document.title = `Yukiko - ${this.guildName || "Guild"} Dashboard`;
    }, 500);
  },
};
</script>

<style scoped>
li {
  list-style-type: none;
  display: inline;
  text-align: center;
  color: #007bff;
}
li:hover {
  border-bottom: 2px solid #ffe52c;
  cursor: pointer;
}
.disabled {
  pointer-events: none;
  cursor: not-allowed;
}
pre {
  color: dimgray;
}
</style>