<template>
  <div class="newPost">
    <div class="blogPost" v-if="isAdmin == true">
      <b-container>
        <b-row>
          <b-col cols="12">
            <div class="message">
              {{ message }}
            </div>
            <div class="error">
              {{ error }}
            </div>
          </b-col>
          <b-col>
            <b-form @submit="onSubmit" @reset="onReset">
              <b-form-group id="input-group-1" label="Title:" label-for="title">
                <b-form-input
                  id="title"
                  v-model="form.title"
                  type="text"
                  placeholder="Post Title"
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="input-group-2"
                label="Description:"
                label-for="description"
              >
                <b-form-input
                  id="description"
                  v-model="form.description"
                  type="text"
                  placeholder="Post Description"
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-group id="input-group-2" label="Post:" label-for="Post">
                <b-form-textarea
                  id="Post"
                  v-model="form.content"
                  placeholder="whats on your mind?"
                  rows="10"
                  max-rows="6"
                ></b-form-textarea>
              </b-form-group>

              <b-button
                type="submit"
                variant="primary"
                style="margin-right: 1rem"
                >Submit</b-button
              >
              <b-button type="reset" variant="danger">Reset</b-button>
            </b-form>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "newPost",
  data() {
    return {
      message: "",
      error: "",
      form: {
        title: "",
        description: "",
        content: "",
      },
      isAdmin: false,
    };
  },
  beforeMount() {
    axios
      .get(`${process.env.VUE_APP_URI}/api/agi/user/profile`, {
        withCredentials: true,
      })
      .then((res) => {
        this.isAdmin = res.data.admin;
      });
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      if(this.isAdmin == false) return this.$router.push("/");
      axios
        .post(`${process.env.VUE_APP_URI}/api/agi/blog/post`, this.form, {
          withCredentials: true,
        })
        .then((res) => {
          if (res.statusCode == 200) {
            this.message = "Post Successfully Created";
          } else {
            this.error = `Error Saving post: ${res.statusCode} ${res.data}`;
          }
        })
        .catch((err) => {
            console.log(err);
          this.error = `Error Saving post: ${err.message}`;
        });
    },
    onReset() {
      this.form.title = "";
      this.form.description = "";
      this.form.content = "";
    },
  },
};
</script>