<template>
  <div class="元気ですか">
    <b-container>
      <b-row>
        <b-col cols="12">
          <h2 class="text-center">
            <b-icon icon="bar-chart-fill"></b-icon>
            Leveling
          </h2>
        </b-col>
        <b-col cols="6">
          <h3>Experience</h3>
          <b-card
          title="Notification"
          bg-variant="dark"
          text-variant="white"
          class="mb-2"
          >
          <b-card-text>
            <b-form-checkbox
                    id="leveling"
                    v-model="notifications"
                    name="leveling"
                    value="true"
                    unchecked-value="false"
                    disabled
                  >
                  {{ notifications ? 'Enabled' : 'Disabled' }}
            </b-form-checkbox>
          </b-card-text>
          </b-card>
        </b-col>
        <b-col cols="6" class="mt-4">
          <b-card
          title="Channels"
          bg-variant="dark"
          text-variant="white"
          class="mb-2 mt-3"
          >
          <b-card-text>
            <b-form @submit="onSubmitChannel">
            <b-form-select
              vartiant="dark"
              v-model="selectedRank"
              :options="channels"
            ></b-form-select>
            <b-button type="submit" variant="outline-primary" class="mt-2">{{
              $t("logeduser.guilds.guildDash.utility.submit")
            }}</b-button>
          </b-form>
            <i><small>The Default input will send the notification in the channel the user sent their last message.</small></i>
          </b-card-text>
          </b-card>
        </b-col>
        <b-col cols="6">
          <b-card
          title="Reward (Soon!)"
          bg-variant="dark"
          text-variant="white"
          class="mb-2"
          >
            <b-card-text>
              <p>
              This Feature is not yet availiable. <br>
              This is a work in progress that should come soon! <br>
              This feature is planned to be premium only.
              </p>
            </b-card-text>
          </b-card>
        </b-col>
        <b-col cols="6">
          <b-card
          title="XP Multiplier (Soon!)"
          bg-variant="dark"
          text-variant="white"
          class="mb-2"
          >
            <b-card-text>
              <p>
              This Feature is not yet availiable. <br>
              This is a work in progress that should come soon! <br>
              This feature is planned to be premium only.
              </p>
            </b-card-text>
          </b-card>
        </b-col>
        <b-col>
          <b-card
          title="Boost (Soon!)"
          bg-variant="dark"
          text-variant="white"
          class="mb-2"
          >
            <b-card-text>
              <p>
              This Feature is not yet availiable. <br>
              This is a work in progress that should come soon! <br>
              This feature is planned to be premium only.
              </p>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
    import axios from "axios";
export default {
  data() {
    return {
        channels: [],
        selectedRank: "",
        notifications: false,
    };
  },
  mounted() {
    axios
      .get(
        `${process.env.VUE_APP_URI}/api/agi/dashboard/guild/${this.$route.params.guildId}/config/channels`,
        { withCredentials: true }
      )
      .then((res) => {
        this.channels = res.data.GuildChannels.channelsList;
        this.channels.unshift({ value: "None", text: "Default" });
        this.selectedRank = res.data.Config.rank
      });
  },
  methods: {
    onSubmitChannel(){
      console.log("submit")
    }
  }
};
</script>