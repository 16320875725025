import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import i18n from './i18n'
import router from './router'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

Vue.config.productionTip = false
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import './registerServiceWorker'
Vue.use(require('vue-moment'));
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

new Vue({
    router,
    i18n,
    render: h => h(App)
}).$mount('#app')