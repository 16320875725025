<template>
    <div class="serverStatus">
        <h1>Server Status</h1>
        <p>
            This page is use to manually change the stats of some cluster. <br>
            Eg: Set the state of a webcluster from ONLINE to OFFLINE, or UNDER MAINTENANCE <br>
            WE will use this for now as we dont have this much cluster to deal with lol.
        </p>
        <b-container>
            <b-row>
                <b-col cols="6">
                    <h2>Add Cluster</h2>
                    <b-form @submit="SubmitCluster">
                        <b-form-group label="Cluster Name">
                            <b-form-input 
                            v-model="clusterName" 
                            placeholder="Cluster Name"
                            type="text"
                            >
                            </b-form-input>
                            <b-button type="submit" variant="outline-primary" class="mt-2">Submit</b-button>
                        </b-form-group>
                    </b-form>
                    <b-form @submit="SubmitNode">
                        <!-- select input -->
                        <b-form-group label="Node Name">
                            <b-form-select 
                            v-model="clusterName" 
                            placeholder="Cluster Name"
                            >
                            <option v-for="cluster in clusters" :key="cluster.cluster">Test: {{ cluster.cluster }}</option>
                            </b-form-select>
                            <b-form-input 
                            v-model="clusterName" 
                            placeholder="Node Name"
                            type="text"
                            >
                            </b-form-input>
                            <b-button type="submit" variant="outline-primary" class="mt-2">Submit</b-button>
                        </b-form-group>
                    </b-form>
                </b-col>
            </b-row>
        </b-container>
        <i>Side node: The status cannot be update here for some reason the console says "it's updated look!"
            but once you check in the database it's not, so I either gonna give up everything on this, 
            or change and do something else instead.
            Look mate, I love ya, but you gotta help me on that one. I'm sick tired of those back end error that make no sense.
        </i>
        <hr />
        <h1>Maintenance Modes</h1>
    <b-alert
      :show="dismissCountDown"
      dismissible
      variant="success"
      @dismissed="dismissCountDown=0"
      @dismiss-count-down="countDownChanged"
    >
      <p>{{ maintenanceMessage }}</p>
      <b-progress
        variant="success"
        :max="dismissSecs"
        :value="dismissCountDown"
        height="4px"
      ></b-progress>
    </b-alert>
        <i>Infos: if true = Maintenance mode is on.</i>
        <!-- Login Maintenance -->
        <b-form @submit="SubmitLogin">
            <b-form-group :label="HomeStatus ? 'Login Status: OFFFLINE' :  'Login Status: ONLINE'">
                <b-form-select
                v-model="LoginStatus" 
                :options="maintenanceModes"></b-form-select>
            <b-button type="submit" variant="outline-primary" class="mt-2">Submit</b-button>
            </b-form-group>
        </b-form>
        <!-- Dashboard Maintenance -->
        <b-form @submit="SubmitUser">
            <b-form-group :label="HomeStatus ? 'Dashboard Status: OFFFLINE' :  'Dashboard Status: ONLINE'">
                <b-form-select
                v-model="LoginStatus" 
                :options="maintenanceModes"></b-form-select>
            <b-button type="submit" variant="outline-primary" class="mt-2">Submit</b-button>
            </b-form-group>
        </b-form>
        <!-- home maintenance -->
        <b-form @submit="SubmitHome">
            <b-form-group :label="HomeStatus ? 'Home Status: OFFFLINE' :  'Home Status: ONLINE'">
                <b-form-select
                v-model="LoginStatus" 
                :options="maintenanceModes"></b-form-select>
            <b-button type="submit" variant="outline-primary" class="mt-2">Submit</b-button>
            </b-form-group>
        </b-form>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    data() {
        return {
            clusterName: "",
            clusters: [],
            maintenanceModes: [
                { text: "ONLINE", value: false },
                { text: "OFFLINE", value: true }
            ],
            LoginStatus: "",
            UserStatus: "",
            HomeStatus: "",
            maintenanceMessage: "",
            dismissSecs: 10,
            dismissCountDown: 0,
            showDismissibleAlert: false,
            error: "",
        }
    },
    beforeMount() {
        axios.get(`${process.env.VUE_APP_URI}/api/agi/admin/servers/`, {withCredentials: true})
        .then(res => {
            const data = res.data;
            data.clusters.forEach(cluster => {
                // push all node in cluster
                cluster.nodes.forEach(node => {
                    this.clusters.push({
                        text: `${cluster.name} - ${node.name}`,
                        value: node.name
                    })
                })
            })
        })
        axios.get(`${process.env.VUE_APP_URI}/api/agi/admin/maintenance/login`, {withCredentials: true})
        .then(res => {
            this.LoginStatus = res.data.status;
            this.maintenanceMessage = res.data.message;
            this.dismissCountDown = this.dismissSecs;
        });
        axios.get(`${process.env.VUE_APP_URI}/api/agi/admin/maintenance/dashboard`, {withCredentials: true})
        .then(res => {
            this.UserStatus = res.data.status;
            this.maintenanceMessage = res.data.message;
            this.dismissCountDown = this.dismissSecs;
        });
        axios.get(`${process.env.VUE_APP_URI}/api/agi/admin/maintenance/home`, {withCredentials: true})
        .then(res => {
            this.HomeStatus = res.data.status;
            this.maintenanceMessage = res.data.message;
            this.dismissCountDown = this.dismissSecs;
        });
    },
    methods: {
        SubmitCluster(event){
            event.preventDefault()
            axios.post(`${process.env.VUE_APP_URI}/api/agi/admin/servers/add`, {clusterName: this.clusterName}, {withCredentials: true})
            .then(res => {
                this.clusterName = "";
                this.error = "";
                this.nodes = res.data;
            })
            .catch(err => {
                this.error = err.response.data.error;
            })
        },
        SubmitNode(event){
            event.preventDefault()
            axios.post(`${process.env.VUE_APP_URI}/api/agi/admin/servers/node/add`, {clusterName: this.clusterName}, {withCredentials: true})
            .then(res => {
                this.nodeName = "";
                this.error = "";
                this.nodes = res.data;
            })
            .catch(err => {
                this.error = err.response.data.error;
            })
        },
        SubmitLogin(event){
            event.preventDefault()
            axios.post(`${process.env.VUE_APP_URI}/api/agi/admin/maintenance/login`, {status: this.LoginStatus}, {withCredentials: true})
            .then((res) =>{
                console.log(res.data);
            })
        }
    }
}
</script>