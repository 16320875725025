<template>
  <div class="WelcomeComp">
    <b-row>
      <b-col cols="12" class="mt-4">
        <h2 class="text-center">Welcome/Farewell messages</h2>
        <p class="text-center"><small>This feature is coming back soon!</small></p>
        <b-col cols="12" class="text-center mt-4 mb-4">
          <b-alert :variant="alert" v-if="message" show>
            {{ message }}
          </b-alert>
        </b-col>
        <b-form-group
          id="welcomeEnable"
          label="Send a Welcome message?"
          label-for="welcomeCheckbox"
          description="Enable or disable this function."
        >
          <b-form-checkbox
            id="welcomeCheckbox"
            v-model="form.welcomeEnable"
            name="Send a Welcome message?"
            value="true"
            unchecked-value="false"
          >
          </b-form-checkbox>
          <b-button variant="outline-danger" class="mt-2" disabled
            >{{ $t("logeduser.guilds.guildDash.utility.submit") }}
          </b-button>
        </b-form-group>
        <b-form @submit="onSubmit">
          <b-form-group
            id="welcomeChannel"
            label="Welcome/Farewell Channel"
            label-for="channelSelect"
            description="Select the channel where you want your welcome message to be sent."
          >
            <b-form-select
              id="channelSelect"
              v-model="form.channel"
              :options="channels"
            ></b-form-select>
          </b-form-group>
          <b-button variant="outline-danger" class="mt-2" disabled
            >{{ $t("logeduser.guilds.guildDash.utility.submit") }}
          </b-button>
        </b-form>
        <b-form>
          <b-form-group>
            <p>Custom Welcome message</p>
            <b-form-textarea
              id="welcomeMessage"
              v-model="form.welcomeMessage"
              placeholder="Welcome message"
              :rows="3"
              :max-rows="3"
              :max-length="2000"
              :state="welcomeMessageState"
              :invalid-feedback="welcomeMessageFeedback"
              :valid-feedback="welcomeMessageFeedback"
            ></b-form-textarea>
          </b-form-group>
          <b-button variant="outline-danger" class="mt-2" disabled
            >{{ $t("logeduser.guilds.guildDash.utility.submit") }}
          </b-button>
        </b-form>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "welcomeChannel",
  data() {
    return {
      form: {
        channel: null,
      },
      channels: [],
      message: null,
      alert: null,
    };
  },
  mounted() {
    axios
      .get(
        `${process.env.VUE_APP_URI}/api/agi/dashboard/guild/${this.$route.params.guildId}/config/channels`,
        { withCredentials: true }
      )
      .then((res) => {
        this.channels = res.data.GuildChannels.channelsList;
        this.channels.push({ text: "None (Disabled.)", value: "None" });
        this.form.channel = res.data.Config.welcome;
      });
  },
  methods: {
    onSubmit() {
      this.alert = null;
      this.message = null;
      axios
        .post(
          `${process.env.VUE_APP_URI}/api/agi/dashboard/guild/${this.$route.params.guildId}/config/channels`,
          {
            welcome: this.form.channel,
          },
          { withCredentials: true }
        )
        .then((res) => {
          if (res.data.message == "No!") {
            this.alert = "danger";
            return (this.message = "You are not authorized to do this.");
          }
          this.message = "Successfully updated the welcome channel.";
          this.alert = "success";
        })
        .catch((err) => {
          console.error(err);
          this.alert = "danger";
          this.message = "Something went wrong.";
        });
    },
  },
};
</script>