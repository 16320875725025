<template>
    <div class="blog">
        <div class="NoPost" v-if="!post">
            <b-container>
                <b-row>
                    <b-col cols="12">
                    <h2>Yo! What's up?</h2>
                </b-col>
                    <b-col cols="12">
                        <h1 class="text-center">
                            No Post :c
                        </h1>
                    </b-col>
                </b-row>
            </b-container>
        </div>
        <b-container v-else>
            <b-row>
                <b-col cols="12">
                    <h2>Yo! What's up?</h2>
                </b-col>
                <b-col cols="6" v-for="post in post" :key="post.id">
                    <b-card 
                    bg-variant="dark"
                    text-variant="white"
                    :title="post.title"
                    style="max-width: 100%;min-width: 100px !important;"
                    class="mt-4 mb-4"
                    >
                    <b-card-text>
                        <p>{{ post.description }}</p>
                        <router-link :to="`/news/${post.slug}`">Read More</router-link>
                    
                    </b-card-text>
                    </b-card>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import axios from "axios";
export default {
    name: "HomeNews",
    data() {
        return {
            post: ""
        };
    },
    async beforeMount() {
        await axios.get(`${process.env.VUE_APP_URI}/api/agi/blog/post`)
        .then(res =>{
           this.post = res.data.posts;
        });
    }
}
</script>
