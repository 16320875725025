import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Dashboard from '../views/logedUser/guilds/Dashboard.vue'
import guildDash from '../views/logedUser/guilds/guildDash.vue'
import Profile from '../views/logedUser/user/profile.vue'
import login from '../views/redirect/login-redirect.vue';
import logout from '../views/redirect/logout-redirect';
import addbot from '../views/redirect/addbot-redirect';
import refresh from '../views/redirect/refresh-redirect';
import admin from '../views/admin/admin';
import privacy from '../views/legal/privacy';
import tos from '../views/legal/tos';
import release from '../views/release';
import status from '../views/status';
import banned from '../views/banned';
import leaderboard from '../views/leaderboards/guilds/levels';
import payment from '../views/logedUser/premium/payment';
import Commands from '../views/commands';

// Blog Routes
import blog from '../views/Blog/User/news';
import blog_post from '../views/Blog/User/PostShow';
import blog_newPost from '../views/Blog/Admin/newPost';

Vue.use(VueRouter)

const routes = [
    // Basic public routes
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/privacy',
        name: 'privacy',
        component: privacy
    },
    {
        path: '/tos',
        name: 'tos',
        component: tos
    },
    {
        path: '/release',
        name: 'release',
        component: release
    },
    {
        path: '/status',
        name: 'status',
        component: status
    },
    {
        path: '/leaderboard/:guildId',
        name: 'leaderboard',
        component: leaderboard
    },
    {
        path: '/commands',
        name: 'commands',
        component: Commands
    },
    // User restricted routes
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard
    },
    {
        path: '/dashboard/guild/:guildId',
        name: 'guildDash',
        component: guildDash
    },
    {
        path: '/profile',
        name: 'Profile',
        component: Profile
    },
    {
        path: '/payment/:guildId',
        name: 'payment',
        component: payment
    },
    // user banned routes
    {
        path: '/banned',
        name: 'banned',
        component: banned
    },
    // Admin restricted routes
    {
        path: '/admin',
        name: 'admin',
        component: admin
    },
    // Blog Routes
    {
        path: '/news',
        name: 'blog',
        component: blog
    },
    {
        path: '/news/:postId',
        name: 'blog_post',
        component: blog_post
    },
    {
        path: '/admin/blog/new',
        name: 'blog_newPost',
        component: blog_newPost
    },
    //Important shit that return to the gateway!!!!
    {
        path: '/addbot/:guildId',
        name: 'addbot',
        component: addbot
    },
    {
        path: '/login',
        name: 'login',
        component: login
    },
    {
        path: '/logout',
        name: 'logout',
        component: logout
    },
    {
        path: '/refresh',
        name: 'refresh',
        component: refresh
    },

    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/About.vue')
    }
]

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
})

export default router