<template>
    <div class="payment">
        <div class="message" v-if="messageType">
            <h2 v-if="messageType == 'ERROR'" class="messageError text-center">{{ message }}</h2>
            <h2 v-if="messageType == 'SUCCESS'" class="messageSuccess text-center">{{ message }}</h2>
            <router-link :to="`/dashboard/guild/${guild.id}`">return to {{ guild.name}}</router-link>
                        <hr>

        </div>
        <b-container v-if="user.isPremium >= 3">
            <b-row >
                <b-col cols="12" class="guildInfo text-center">
                    <b-img :src="guild.icon" rounded="circle"></b-img>
                    <h1>{{ guild.name }}</h1>
                </b-col>
                <b-col cols="12" class="payment">
                    <b-card
                    title="Premium (monthly)"
                    bg-variant="dark"
                    text-variant="white"
                    class="paymentCard"
                    max-width="5rem"
                    >
                    <b-card-text>
                        <p>Montly premium for {{ guild.name }}</p>
                        <p>Price: <s>$5.00</s> <br/>
                        <span class="muted">Fwiend Discount -100%</span> <br/>
                        New price: $0.00
                        </p>
                        <p>Duration: 1 month</p>
                            <b-button
                            variant="outline-primary"
                            @click="Fwiend_pay"
                            >
                            Pay
                            </b-button>
                    </b-card-text>
                    </b-card>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
    import axios from "axios";
export default {
    data() {
        return {
            message: "",
            messageType: "",
            user: "",
            guild: "",
        }
    },
    beforeMount() {
        axios.get(`${process.env.VUE_APP_URI}/api/agi/payment/getuser`, {
            withCredentials: true
        })
        .then(res =>{
            this.user = res.data;
        })
        .then(() =>{
            axios.get(`${process.env.VUE_APP_URI}/api/agi/payment/getguild/${this.$route.params.guildId}`, {withCredentials: true})
            .then((res) =>{
                this.guild = res.data.guild;
            })
        })
    },
    methods: {
        Fwiend_pay() {
            axios.post(`${process.env.VUE_APP_URI}/api/agi/payment/process/${this.guild.id}?type=4`, 
            {withCredentials: true})
            .then((res) => {
                this.message = res.data.message;
                this.messageType = res.data.messageType;
            })
        },
    },
}
</script>

<style scoped>
    .payment {
        padding: 2rem;
    }
    .paymentCard {
        margin-top: 2rem;
    }
    .message {
        margin-top: 2rem;
    }
    .messageError {
        color: red;
    }
    .messageSuccess {
        color: green;
    }
    .muted {
        color: rgb(107, 107, 107);
    }
</style>