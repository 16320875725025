<template>
  <div>
    <b-container class="mt-4">
      <b-row>
        <b-col sm="6" class="mt-4">
          <b-card
            bg-variant="dark"
            text-variant="white"
            :title="userProfile.username"
            :img-src="userProfile.avatar"
            img-alt="Avatar"
            img-top
            tag="article"
            style="max-width: 20rem"
            class="mb-1"
          >
            <b-card-text>
              <p>
                {{ $t("logeduser.profile.profile-card.userID") }}:
                {{ userProfile.userID }} <br />
                {{ $t("logeduser.profile.profile-card.username") }}:
                {{ userProfile.username }} <br />
                {{ $t("logeduser.profile.profile-card.premium") }}:
                {{ userProfile.premium }} <br />
                {{ $t("logeduser.profile.profile-card.locale") }}:
                {{ userProfile.locale }} <br />
                {{ $t("logeduser.profile.profile-card.guilds") }}:
                {{ userProfile.InGuild }} <br />
              </p>
            </b-card-text>
          </b-card>
        </b-col>
        <b-col sm="6" class="mt-4" header-tag="header">
          <b-card
            :title="$t('logeduser.profile.dangerZone.header')"
            tag="article"
            style="max-width: 150rem"
            class="mb-2"
            border-variant="danger"
            header="Danger"
            header-border-variant="danger"
            header-text-variant="danger"
            bg-variant="dark"
            text-variant="danger"
          >
            <template #header>
              <h6 class="mb-0">
                {{ $t("logeduser.profile.dangerZone.head") }}
              </h6>
            </template>
            <b-card-text>
              {{ $t("logeduser.profile.dangerZone.body") }}
            </b-card-text>

            <b-button variant="outline-danger" @click="showModal"
              ><b-icon icon="trash"></b-icon>
              {{ $t("logeduser.profile.dangerZone.delete-btn") }}
            </b-button>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <b-modal
      header-bg-variant="danger"
      body-bg-variant="dark"
      body-text-variant="light"
      ref="DeleteDataModal"
      hide-footer
      :title="$t('logeduser.profile.dangerZone.delete-conf.modal-title')"
    >
      <div class="d-block text-center">
        <h3>{{ $t("logeduser.profile.dangerZone.delete-conf.head") }}</h3>
        <p>{{ $t("logeduser.profile.dangerZone.delete-conf.body") }}</p>
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="DeleteUser"
        >{{
          $t("logeduser.profile.dangerZone.delete-conf.agreed-btn")
        }}</b-button
      >
      <b-button
        class="mt-2"
        variant="outline-warning"
        block
        @click="hideModal"
        >{{
          $t("logeduser.profile.dangerZone.delete-conf.disagreed-btn")
        }}</b-button
      >
    </b-modal>
  </div>
</template>

    <script>
import axios from "axios";
export default {
  name: "ProfileComp",
  data() {
    return {
      userProfile: {
        userID: "",
        username: "",
        avatar: "",
        premium: "",
        admin: "",
        locale: "",
        InGuild: "",
        guilds: "",
      },
    };
  },
  mounted() {
    document.title = `Yukiko - User Profile`;
    axios
      .get(process.env.VUE_APP_URI + "/api/agi/user/profile", {
        withCredentials: true,
      })
      .then((res) => {
        if (res.data.error == "NoUser") {
          return this.$router.push("/login");
        }
        const userPremium = {
          0: "Free Account",
          1: "Premium (month)",
          2: "Premium (Life time)",
          3: "Developper",
          4: "Premium Fwiend Flag",
        };
        this.userProfile.userID = res.data.userID;
        this.userProfile.username = res.data.username;
        this.userProfile.avatar = res.data.avatar;
        this.userProfile.premium = userPremium[res.data.premium];
        this.userProfile.admin = res.data.admin;
        this.userProfile.locale = res.data.locale;
        this.userProfile.InGuild = res.data.InGuilds;
        this.userProfile.guilds = res.data.guilds;
      });
  },
  methods: {
    showModal() {
      this.$refs["DeleteDataModal"].show();
    },
    hideModal() {
      this.$refs["DeleteDataModal"].hide();
    },
    toggleModal() {
      this.$refs["DeleteDataModal"].toggle("#toggle-btn");
    },
    DeleteUser() {
      axios
        .delete(`${process.env.VUE_APP_URI}/api/agi/user/profile`, {
          withCredentials: true,
        })
        .then(() => {
          this.userProfile.userID = "";
          this.userProfile.username = "";
          this.userProfile.avatar = "";
          this.userProfile.premium = "";
          this.userProfile.admin = "";
          this.userProfile.locale = "";
          this.userProfile.InGuild = "";
          this.userProfile.guilds = "";
          window.location.href = process.env.VUE_APP_FRONT;
        });
    },
  },
};
</script>