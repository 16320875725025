<template>
    <div class="ポガーズ">
        <h1>Logging you out.</h1>
    </div>
</template>

<script>
export default {
    mounted(){
        window.location.href = `${process.env.VUE_APP_URI}/api/agi/auth/logout`
    }
}
</script>