<template>
  <div class="Admin">
    <b-container>
      <b-row>
				<hr>
        <b-col cols="12" class="mt-4">
          <div class="text-center mt-4">
            <h2>Admin Zone ┬┴┬┴┤･ω･)ﾉ</h2>
						<h4>
							<ul>
                <li @click="comp = 'manage'">Manage</li>
                |
                <li @click="comp = 'guilds'">Guilds</li>
                |
                <li @click="comp = comp" style="cursor: not-allowed;">Users</li>
                |
                <li @click="comp = 'status'" style="color: red;">STATUS</li>
              </ul>
						</h4>
          </div>
        </b-col>
				<b-col cols="12">
						<Guilds v-if="comp == 'guilds'" />
						<Manage v-if="comp == 'manage'" />
						<Users v-if="comp == 'users'" />
            <Status v-if="comp == 'status'" />
				</b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Guilds from "../../components/adminComp/adminGuilds";
import Manage from "../../components/adminComp/adminManage";
import Users from "../../components/adminComp/adminUsers";
import Status from "../../components/adminComp/adminStatus";
import axios from "axios"
export default {
  components: {
    Guilds,
    Status,
    Manage,
    Users,
  },
  data() {
    return {
      comp: 'manage',
    };
  },
  beforeMount() {
    axios.get(`${process.env.VUE_APP_URI}/api/agi/user/profile`, {withCredentials: true})
    .then(res =>{
      const data = res.data;
      if(data.admin == false){
        this.$router.push('/')
      }
    })
  }
};
</script>

<style scoped>
li {
  list-style-type: none;
  display: inline;
  text-align: center;
  color: #007bff;
}
li:hover {
  border-bottom: 2px solid #ffe52c;
  cursor: pointer;
}
</style>