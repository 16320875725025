<template>
  <div class="メイン">
    <b-container class="mt-4">
      <b-row>
        <b-col cols="12">
          <router-link to="/refresh"
            ><b-icon icon="arrow-clockwise"></b-icon>
            {{ $t("logeduser.guilds.dashboard.refreshList") }}</router-link
          >
          <table class="table">
            <tbody>
              <tr
                v-for="guild in guilds"
                :key="guild.id"
                class="text-left"
                style="color: white"
              >
                <th scope="row">
                  <b-avatar
                    variant="rgba(0, 0, 0, 0)"
                    icon="people-fill"
                    :src="
                      'https://cdn.discordapp.com/icons/' +
                      guild.id +
                      '/' +
                      guild.icon
                    "
                  ></b-avatar>
                  - {{ guild.name }}
                  <span v-if="verified.includes(guild.id)">
                    <b-icon icon="patch-check" id="Verified"></b-icon>
                  </span>
                  <b-tooltip target="Verified" triggers="hover">
                    {{ $t("logeduser.guilds.dashboard.verified") }}
                  </b-tooltip>
                  <span v-if="dev.includes(guild.id)">
                    <b-icon icon="tools" id="Dev"></b-icon>
                    <b-tooltip target="Dev" triggers="hover">
                      {{ $t("logeduser.guilds.dashboard.dev") }}
                    </b-tooltip>
                  </span>
                  <span v-if="guilds.isPremium > 0">
                    <b-icon icon="star" id="Premium"></b-icon
                  >
                  </span>
                </th>
                <td>
                  <router-link :to="`/dashboard/guild/${guild.id}`"
                    ><b-button variant="outline-primary"
                      ><b-icon icon="tools"></b-icon>
                      {{
                        $t("logeduser.guilds.dashboard.dashboard-btn")
                      }}</b-button
                    ></router-link
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "GuildList",
  data() {
    return {
      guilds: [],
      verified: [
        "806125601042857995",
        "712651869104635994",
        "826038009811435530",
        "671897475589603331",
        "685665866750361624",
        "700522129254252544",
        "704008812905889913",
        "424634199241129995",
        "548167755589877783",
        "647689682381045772",
        "472523359754780703",
        "900101624800890890",
        "110373943822540800",
        "439866052684283905",
      ],
      dev: [
        "746566722630320258",
        "806125601042857995",
        "740903248382132355",
        "612216766680268811",
        "749318540594774067",
        "758571015948206100",
        "466707368076509212",
        "935027390869020693",
      ],
    };
  },
  beforeMount() {
    axios
      .get(`${process.env.VUE_APP_URI}/api/agi/user/profile`, {
        withCredentials: true,
      })
      .then((res) => {
        const data = res.data;
        if (data.isban == true) return this.$router.push("/banned");
      });
    axios
      .get(process.env.VUE_APP_URI + "/api/agi/dashboard/getguilds/user", {
        withCredentials: true,
      })
      .then((res) => {
        if (res.data.error == "NoUser") return this.$router.push("/login");
        this.guilds = res.data;
      });
  },
  mounted() {
    document.title = "Yukiko - Dashboard";
  },
};
</script>

<style scoped>
.b-avatar img {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
}
.dashLink {
  float: right;
}
td {
  text-align: right;
}
.table {
  border-spacing: 5px;
  border-collapse: collapse;
}
tr:nth-child(even) {
  background-color: #1a1b1f;
}
</style>
