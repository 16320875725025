<template>
  <div class="Managment">
    <div class="text-center">
      <h3>{{ message }}</h3>
    </div>
    <h1>Managment</h1>
    <b-row>
      <b-col cols="6">
        <h3 class="mt-4">
          Admins Managment <small><i>Soon!</i></small>
        </h3>
        <!-- Add Admins -->
        <b-form @submit="SubAdminAdd">
          <b-input-group prepend="Add Admin" class="mt-3">
            <b-form-input
              v-model="adminAdd"
              id="adminAdd"
              type="text"
              placeholder="User ID"
            ></b-form-input>
            <b-input-group-append>
              <b-button type="submit" variant="outline-primary"
                >Submit</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </b-form>
        <!-- Remove Admin -->
        <b-form @submit="SubAdminDel">
          <b-input-group prepend="Remove Admin" class="mt-3">
            <b-form-input
              v-model="adminDel"
              id="adminRemove"
              type="text"
              placeholder="User ID"
            ></b-form-input>
            <b-input-group-append>
              <b-button type="submit" variant="outline-primary"
                >Submit</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </b-form>
      </b-col>
      <b-col cols="6">
        <h3 class="mt-4">
          Guilds Managment <small><i>Soon!</i></small>
        </h3>
        <!-- Ban guild -->
        <b-input-group prepend="Ban Guild" class="mt-3">
          <b-form-input
            id="GuildBan"
            type="text"
            placeholder="Guild ID"
          ></b-form-input>
          <b-input-group-append>
            <b-button variant="outline-primary">Submit</b-button>
          </b-input-group-append>
        </b-input-group>
        <!-- Unban guild -->
        <b-input-group prepend="Unban Guild" class="mt-3">
          <b-form-input
            id="Guild Unban"
            type="text"
            placeholder="Guild ID"
          ></b-form-input>
          <b-input-group-append>
            <b-button variant="outline-primary">Submit</b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col cols="12">
        <h3 class="mt-4">
          User Managment <small><i>Soon!</i></small>
        </h3>
        <!-- Ban User -->
        <b-form @submit="SubUserBan">
          <b-input-group prepend="Ban User" class="mt-3">
            <b-form-input
              v-model="userBan"
              id="userBan"
              type="text"
              placeholder="User ID"
            ></b-form-input>
            <b-input-group-append>
              <b-button variant="outline-primary" type="submit">Submit</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form>
        <!-- UnBan user -->
        <b-form @submit="SubUserUnBan">
        <b-input-group prepend="UnBan User" class="mt-3">
          <b-form-input
            v-model="userUnban"
            id="userUnban"
            type="text"
            placeholder="User ID"
          ></b-form-input>
          <b-input-group-append>
            <b-button variant="outline-primary" type="submit">Submit</b-button>
          </b-input-group-append>
        </b-input-group>
        </b-form>
      </b-col>
      <b-col cols="12">
        <h3 class="mt-4">Admin list (2)</h3>
        <ul>
          <li>
            <h4>
              <b-avatar
                variant="info"
                :src="admin.rise.avatar"
                size="4rem"
              ></b-avatar>
              {{admin.rise.tag}}
            </h4>
          </li>
          <li>
            <h4>
              <b-avatar
                variant="info"
                :src="admin.mako.avatar"
                size="4rem"
              ></b-avatar>
              {{admin.mako.tag}}
            </h4>
          </li>
        </ul>
      </b-col>
      <b-col cols="12">
        <h3>Blog Posts</h3>
        <b-button variant="outline-primary" to="/admin/blog/new">new post</b-button>
      </b-col>
    </b-row>
  </div>
</template>

// ToDo: 
// Dynamic Admin list
// Make all the forms working

<script>
import axios from "axios";
export default {
  data() {
    return {
      isAdmin: "",
      message: "",
      error: "",
      adminAdd: "",
      adminDel: "",
      userBan: "",
      userUnban: "",
      admin: {
        mako: {
          username: "",
          avatar: "",
          tag: "",
        },
        rise: {
          username: "",
          avatar: "",
          tag: "",
        }
      },

    };
  },
  beforeMount() {
    axios
      .get(`${process.env.VUE_APP_URI}/api/agi/user/profile`, {
        withCredentials: true,
      })
      .then((res) => {
        this.isAdmin = res.data.admin;
      });
      axios.get(`${process.env.VUE_APP_URI}/api/agi/admin/avatar`, {withCredentials: true})
      .then(res =>{
        const data = res.data;
        this.admin.mako.username = data.mako.username;
        this.admin.mako.avatar = data.mako.url;
        this.admin.mako.tag = `${data.mako.username}#${data.mako.tag}`
        this.admin.rise.username = data.rise.username;
        this.admin.rise.avatar = data.rise.url;
        this.admin.rise.tag = `${data.rise.username}#${data.rise.tag}`
      })
  },
  methods: {
    SubAdminAdd(event) {
      event.preventDefault();
      axios
        .post(
          `${process.env.VUE_APP_URI}/api/agi/admin/admin/${this.adminAdd}/add`,
          {},
          { withCredentials: true }
        )
        .then((res) => {
          const data = res.data;
          this.message = data.message;
        })
        .catch((error) => {
          this.message = error;
        });
    },
    SubAdminDel(event) {
      event.preventDefault();
      axios
        .post(
          `${process.env.VUE_APP_URI}/api/agi/admin/admin/${this.adminDel}/remove`,
          {},
          { withCredentials: true }
        )
        .then((res) => {
          const data = res.data;
          this.message = data.message;
        })
        .catch((error) => {
          this.message = error;
        });
    },
    SubUserBan(event) {
      event.preventDefault();
      axios
        .post(
          `${process.env.VUE_APP_URI}/api/agi/admin/ban/${this.userBan}/add`,
          {},
          { withCredentials: true }
        )
        .then((res) => {
          const data = res.data;
          this.message = data.message;
        })
        .catch((error) => {
          this.message = error;
        });
    },
    SubUserUnBan(event) {
      event.preventDefault();
      axios
        .post(
          `${process.env.VUE_APP_URI}/api/agi/admin/ban/${this.userUnban}/del`,
          {},
          { withCredentials: true }
        )
        .then((res) => {
          const data = res.data;
          this.message = data.message;
        })
        .catch((error) => {
          this.message = error;
        });
    },
  },
};
</script>

<style scoped>
ul {
  list-style-type: none;
}
</style>