<template>
  <div>
    <h1>Guilds List</h1>
    <b-container>
      <b-row>
        <b-col>
            <table class="table">
          <tbody>
            <tr
              v-for="guild in guilds"
              :key="guild.sid"
              class="text-left"
              style="color: white"
            >
              <th scope="row">
                <b-avatar
                  variant="rgba(0, 0, 0, 0)"
                  icon="people-fill"
                  :src="guild.sicon"
                ></b-avatar>
                - {{ guild.sname }} ({{guild.sid}})
                <span v-if="guild.name == 'Yukiko Dev Team'">
                  <b-icon icon="patch-check"></b-icon
                ></span>
              </th>
            </tr>
          </tbody>
          </table>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "adminGuilds",
  data() {
    return {
      isAdmin: "",
      guilds: [],
    };
  },
  beforeMount() {
    axios
      .get(process.env.VUE_APP_URI + "/api/agi/auth/getuser", {
        withCredentials: true,
      })
      .then((res) => {
        let data = res.data;
        if (data.isAdmin == false) {
          return this.$router.push("/dashboard");
        }
        this.username = data.username;
        this.avatar = data.avatar;
        this.isAdmin = data.isAdmin;
      });
    axios
      .get(process.env.VUE_APP_URI + "/api/agi/dashboard/guilds", {
        withCredentials: true,
      })
      .then((res) => {
        this.guilds = res.data.botGuild;
      });
  },
};
</script>

<style scoped>
.b-avatar img {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
}
.dashLink {
  float: right;
}
td {
  text-align: right;
}
.table {
  border-spacing: 5px;
  border-collapse: collapse;
}
tr:nth-child(even) {
  background-color: #1a1b1f;
}
</style>