<template>
  <div class="">
    <b-container>
      <b-row>
        <b-col cols="12" class="text-center">
          <h1>{{$t('logeduser.status.title')}}</h1>
          <p>{{$t('logeduser.status.wip')}}</p>
          <p>
            {{$t('logeduser.status.info')}}
            <a
              href="https://yukikoapp.statuspage.io"
              target="_blank"
              rel="noopener noreferrer"
              >Status page</a
            > <br />
            {{$t('logeduser.status.twitter')}}
            <a
              href="https://Twitter.com/YukikoApp"
              target="_blank"
              rel="noopener noreferrer"
              >@YukikoApp</a
            >.
          </p>
        </b-col>
        <b-col cols="12">
          <h2>{{$t('logeduser.status.shardStatus')}}:</h2>
          <p>
            For best performance Yukiko is working with a Sharding System which
            mean we run multiple instance of Yukiko per Server. <br />
            We are running 5 Shard per server, each server is part of a cluster of 5 server each. <br>
                        Server names are arbitrary, depending on what we were watching/liking at the moment.
          </p>
          <b>{{$t('logeduser.status.lastUpdate')}}:</b> <span v-if="update">{{ update | moment("MMMM Do YYYY, h:mm a") }}</span> <span v-else>Unknown.</span>
        </b-col>
        <h3>Bot's Cluster</h3>
        <b-col cols="12"
          ><h4 class="mt-4">Myth</h4></b-col>
        <b-col cols="2">
          <b>Ina</b><br />
          <div v-for="shard in shards" :key="shard.shardId">
            <span v-if="shard.status == 'Online' "><span class="Online display-5">●</span> {{ shard.shardId }}: {{ shard.ping }}ms. <!--{{ shard.guilds }} Guilds--></span>
            <span v-else-if="shard.status == 'Offline' "><span class="Offline">●</span> {{ shard.shardId }}: 0ms.</span>
            <span v-else>{{ shard.shardId }}: , {{ shard.status }} {{ shard.ping }}ms.</span>
            <br />
          </div>
        </b-col>
        <b-col class="mt-4" cols="12"></b-col>
        <b-col cols="12" class="mt-4">
          <h2>Web Clusters</h2>
        </b-col>
        <b-col cols="3">
          <h4>Fallen</h4>
          Shiro: {{ WebClusters.fallen}}<br />
          Suzune: {{ WebClusters.fallen}}<br />
          Makoto: {{ WebClusters.fallen}}<br />
          Sadayo: {{ WebClusters.fallen}}<br />
        </b-col>
        <b-col cols="3">
            <h4>Fantasy</h4>
            Mitsuru: {{ WebClusters.fantasy}}<br />
            Orpheus: {{ WebClusters.fantasy}}<br />
            Sumire: {{ WebClusters.fantasy}}<br />
            Sadayo: {{ WebClusters.fantasy}}<br />
          </b-col>
          <b-col cols="3">
            <h4>Force</h4>
            Tali: {{ WebClusters.force }}<br />
            Rukia: {{ WebClusters.force }}<br />
            Winry: {{ WebClusters.force }}<br />
            Yoruichi: {{ WebClusters.force }}<br />
          </b-col>
        <!-- <b-col cols="3">
            <h4>Hope</h4>
            Cadenza-US: Online<br />
            Aqua-US: Online<br />
            Kurisu-US: Online<br />
            Megumin-US: Online<br />
          </b-col> -->

        <!-- <b-col cols="12" class="mt-4">
          <h2>{{$t('logeduser.status.general')}}</h2>
          <b-embed
            type="iframe"
            aspect="16by9"
            src="https://p.datadoghq.com/sb/2e75yt681qobg9k1-497514823ed978d66e228f2a8a58537c?darkmode=true"
          ></b-embed>
        </b-col> -->
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      update: "",
      shards: [],
      cluster: [],
      WebClusters:{
        fallen: "Offline",
        fantasy: "Offline",
        force: "Offline"
      }
    };
  },
  beforeMount() {
    axios.get(`${process.env.VUE_APP_URI}/api/agi/bot/status`)
    .then(res =>{
      this.WebClusters.fallen = res.data.cluster[0].status;
      this.WebClusters.fantasy = res.data.cluster[1].status;
      this.WebClusters.force = res.data.cluster[2].status;
    })
    axios
      .get(`${process.env.VUE_APP_URI}/api/agi/bot/shards`)
      .then((res) => {
        this.shards = res.data.shardData;
        this.update = res.data.lastCheck;
      })
      .catch(() => {
        this.shards = [
          {
            shardId: "0",
            status: "Offline",
            Ping: "0",
          },
          {
            shardId: "1",
            status: "Offline",
            Ping: "0",
          },
          {
            shardId: "2",
            status: "Offline",
            Ping: "0",
          },
          {
            shardId: "3",
            status: "Offline",
            Ping: "0",
          },
          {
            shardId: "4",
            status: "Offline",
            Ping: "0",
          },
        ];
      });
    this.GetPing();
    // this.GetCluster();
  },
  methods: {
    GetPing() {
      setInterval(() => {
        axios
          .get(`${process.env.VUE_APP_URI}/api/agi/bot/shards`)
          .then((res) => {
            this.shards = res.data.shardData;
            this.update = res.data.lastCheck;
            console.log(res.data)
          })
          .catch(() => {
            this.shards = [
              {
                shardId: "0",
                status: "Offline",
                Ping: "0",
              },
              {
                shardId: "1",
                status: "Offline",
                Ping: "0",
              },
              {
                shardId: "2",
                status: "Offline",
                Ping: "0",
              },
              {
                shardId: "3",
                status: "Offline",
                Ping: "0",
              },
              {
                shardId: "4",
                status: "Offline",
                Ping: "0",
              },
            ];
            this.update = Date.now();
          });
      }, 20000);
    },
    getWebCluster (){
      setInterval(() => {
        axios.get("https://api.asthriona.com/yukiko/cluster")
        .then(res =>{
          this.WebClusters.fallen = res.data.fallen;
          this.WebClusters.fantasy = res.data.fantasy;
        })
      }, 20000);
    }
  },
};
</script>

<style scoped>
.background {
  background-color: black;
}
.Online {
  color: #00ff00;
}
.Offline {
  color: #ff0000;
}
</style>