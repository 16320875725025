<template>
  <div class="GuildLogs">
    <b-container>
      <b-row>
        <b-col cols="12">
          <h2 class="text-center">Guild Logging</h2>
        </b-col>
        <!-- Alerts -->
        <!-- アラート -->
        <b-col cols="12">
          <b-alert show :variant="alert.type" v-if="alert.type" dismissible>
            <b-row>
              <b-col cols="12">
                <h3 v-if="alert.type == 'danger'">Oops! An error Happened!</h3>
                <h3 v-else-if="alert.type == 'warning'">Warning!</h3>
                <h3 v-else>Success!</h3>
              </b-col>
              <b-col cols="12">
                <p>
                  {{ alert.message }}
                </p>
              </b-col>
            </b-row>
          </b-alert>
        </b-col>
        <!-- Moderation Logging -->
        <!-- モデレートロギング -->
        <b-col cols="6">
          <b-card bg-variant="dark" text-variant="white" class="mb-2">
            <b-card-text>
              <b-form @submit="moderationSubmit">
                <h3>
                  Moderation Logs <br>
                  <small>Kick, Ban, Un-ban</small>
                  <b-form-checkbox
                    id="moderation"
                    v-model="logs.moderationLogs"
                    name="moderation"
                    value="true"
                    unchecked-value="false"
                    switch
                  >
                  </b-form-checkbox>
                </h3>
                <b-form-select
                  variant="dark"
                  v-model="logs.moderationLogsChannel"
                  :options="channels"
                >
                </b-form-select>
                <b-button type="submit" variant="outline-primary" class="mt-2">Submit</b-button>
              </b-form>
            </b-card-text>
          </b-card>
        </b-col>
        <!-- Channel Logging -->
        <!-- チャンネルロギング -->
        <b-col cols="6">
          <b-card bg-variant="dark" text-variant="white" class="mb-2">
            <b-card-text>
              <b-form @submit="channelSubmit">
                <h3>
                  Channel Logs <br>
                  <small>Created, Removed, Updated</small>
                  <b-form-checkbox
                    id="channel"
                    v-model="logs.ChannelLogs"
                    name="channel"
                    value="true"
                    unchecked-value="false"
                    switch
                  >
                  </b-form-checkbox>
                </h3>
                <b-form-select
                  variant="dark"
                  v-model="logs.channelLogsChannel"
                  :options="channels"
                >
                </b-form-select>
                <b-button type="submit" variant="outline-primary" class="mt-2">Submit</b-button>
              </b-form>
            </b-card-text>
          </b-card>
        </b-col>
        <!-- message Logging -->
        <!-- メッセージロギング -->
        <b-col cols="6">
          <b-card bg-variant="dark" text-variant="white" class="mb-2">
            <b-card-text>
              <b-form @submit="messageSubmit">
                <h3>
                  Messages Logs <br>
                  <small>Edited, Deleted</small>
                  <b-form-checkbox
                    id="message"
                    v-model="logs.messageLogs"
                    name="message"
                    value="true"
                    unchecked-value="false"
                    switch
                    disabled
                  >
                  </b-form-checkbox>
                </h3>
                <b-form-select
                  variant="dark"
                  v-model="logs.messageLogsChannel"
                  :options="channels"
                  disabled
                >
                </b-form-select>
                <b-button type="submit" variant="outline-danger" class="mt-2" disabled>Submit</b-button>
              </b-form>
              <small class="red">Message Logging has been disabled for Discord users privacy reasons.</small>
            </b-card-text>
          </b-card>
        </b-col>
        <!-- member Logging -->
        <!-- メンバーロギング -->
        <b-col cols="6">
          <b-card bg-variant="dark" text-variant="white" class="mb-2">
            <b-card-text>
              <b-form @submit="memberSubmit">
                <h3>
                  Members Logs <br>
                  <small>Added/removed role, Nickname update...</small>
                  <b-form-checkbox
                    id="member"
                    v-model="logs.memberLogs"
                    name="member"
                    value="true"
                    unchecked-value="false"
                    switch
                    >
                  </b-form-checkbox>
                </h3>
                <b-form-select
                  variant="dark"
                  v-model="logs.memberLogsChannel"
                  :options="channels"
                >
                </b-form-select>
                <b-button type="submit" variant="outline-primary" class="mt-2">Submit</b-button>
              </b-form>
            </b-card-text>
          </b-card>
        </b-col>
        <!-- roles Logging -->
        <!-- ルールロギング -->
        <b-col cols="6">
          <b-card bg-variant="dark" text-variant="white" class="mb-2">
            <b-card-text>
              <b-form @submit="roleSubmit">
                <h3>
                  Roles Logs  - <i>Coming Soon!</i> <br>
                  <small>Created, Removed, Permission update</small>
                  <b-form-checkbox
                    id="role"
                    v-model="logs.rolesLogs"
                    name="role"
                    value="true"
                    unchecked-value="false"
                    switch
                  disabled>
                  </b-form-checkbox>
                </h3>
                <b-form-select
                  variant="dark"
                  v-model="logs.rolesLogsChannel"
                  :options="channels"
                disabled>
                </b-form-select>
                <b-button type="submit" variant="outline-danger" class="mt-2" disabled>Submit</b-button>
              </b-form>
            </b-card-text>
          </b-card>
        </b-col>
        <!-- Voice Logging -->
        <!-- ボイスロギング -->
        <b-col cols="6">
          <b-card bg-variant="dark" text-variant="white" class="mb-2">
            <b-card-text>
              <b-form @submit="voiceSubmit">
                <h3>
                  Voice Logs - <i>Coming Soon!</i> <br>
                  <small>Join, Leave, Mute, Deafen, Unmute, Un-deafen</small>
                  <b-form-checkbox
                    id="voice"
                    v-model="logs.voiceLogs"
                    name="voice"
                    value="true"
                    unchecked-value="false"
                    switch
                  disabled>
                  </b-form-checkbox>
                </h3>
                <b-form-select
                  variant="dark"
                  v-model="logs.voiceLogsChannel"
                  :options="channels"
                disabled>
                </b-form-select>
                <b-button type="submit" variant="outline-danger" class="mt-2" disabled>Submit</b-button>
              </b-form>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "guild-logs",
  data() {
    return {
      logs: {
        moderationLogs: "",
        moderationLogsChannel: "",
        ChannelLogs: "",
        channelLogsChannel: "",
        message: "",
        messageChannel: "",
        memberLogs: "",
        memberLogsChannel: "",
        rolesLogs: "",
        rolesLogsChannel: "",
        voiceLogs: "",
        voiceLogsChannel: "",
      },
      channels: [],
      alert: {
        type: "",
        message: "",
      },
    };
  },
  created() {
    // get logs configs
    //ギルドロギング設定を取得する
    axios
      .get(
        `${process.env.VUE_APP_URI}/api/agi/dashboard/logs/${this.$route.params.guildId}/config`,
        { withCredentials: true }
      )
      .then((res) => {
        this.logs = res.data.config;
      })
            .catch((err) => {
        this.alert.type = "danger";
        this.alert.message = err.response.data.message;
      });
    // get channel list
    // チャンネルリストを取得する
    axios
      .get(
        `${process.env.VUE_APP_URI}/api/agi/dashboard/guild/${this.$route.params.guildId}/config/channels`,
        { withCredentials: true }
      )
      .then((res) => {
        this.channels = res.data.GuildChannels.channelsList;
      })
      .catch((err) => {
        this.alert.type = "danger";
        this.alert.message = err.response.data.message;
      });
  },
  methods: {
    //   Moderation Post
    //   モデレーション投稿
      moderationSubmit(event) {
          event.preventDefault();
          axios.post(`${process.env.VUE_APP_URI}/api/agi/dashboard/logs/${this.$route.params.guildId}/config/mod`, {
            moderationLogs: this.logs.moderationLogs,
            moderationLogsChannel: this.logs.moderationLogsChannel
          }, { withCredentials: true }).then((res) => {
              this.alert.message = res.data.message;
              this.alert.type = "success";
          })
          .catch((err) => {
                this.alert.message = err.response.data.message;
                this.alert.type = "danger";
          });
      },
    //   Channel Post
    //   チャンネル投稿
      channelSubmit(event) {
          event.preventDefault();
          axios.post(`${process.env.VUE_APP_URI}/api/agi/dashboard/logs/${this.$route.params.guildId}/config/channel`, {
              ChannelLogs: this.logs.ChannelLogs,
              channelLogsChannel: this.logs.channelLogsChannel,
          }, { withCredentials: true }).then((res) => {
              this.alert.message = res.data.message;
              this.alert.type = "success";
          })
          .catch((err) => {
                this.alert.message = err.response.data.message;
                this.alert.type = "danger";
          });
      },
    //   Message Post
    //   メッセージ投稿
      messageSubmit(event) {
          event.preventDefault();
          axios.post(`${process.env.VUE_APP_URI}/api/agi/dashboard/logs/${this.$route.params.guildId}/config/message`, {
              message: this.logs.message,
              messageChannel: this.logs.messageChannel,
          }, { withCredentials: true }).then((res) => {
              this.alert.message = res.data.message;
              this.alert.type = "success";
          })
          .catch((err) => {
                this.alert.message = err.response.data.message;
                this.alert.type = "danger";
          });
      },
          //   Member Post
          //   メンバー投稿
      memberSubmit(event) {
          event.preventDefault();
          axios.post(`${process.env.VUE_APP_URI}/api/agi/dashboard/logs/${this.$route.params.guildId}/config/member`, {
              memberLogs: this.logs.memberLogs,
              memberLogsChannel: this.logs.memberLogsChannel,
          }, { withCredentials: true }).then((res) => {
              this.alert.message = res.data.message;
              this.alert.type = "success";
          })
          .catch((err) => {
                this.alert.message = err.response.data.message;
                this.alert.type = "danger";
          });
      },
          //   Roles Post
          //   ロール投稿
      roleSubmit(event) {
          event.preventDefault();
          axios.post(`${process.env.VUE_APP_URI}/api/agi/dashboard/logs/${this.$route.params.guildId}/config/roles`, {
              rolesLogs: this.logs.rolesLogs,
              rolesLogsChannel: this.logs.rolesLogsChannel,
          }, { withCredentials: true }).then((res) => {
              this.alert.message = res.data.message;
              this.alert.type = "success";
          })
          .catch((err) => {
                this.alert.message = err.response.data.message;
                this.alert.type = "danger";
          });
      },
          //   voice Post
          //   ボイスチャンネル投稿
      voiceSubmit(event) {
          event.preventDefault();
          axios.post(`${process.env.VUE_APP_URI}/api/agi/dashboard/logs/${this.$route.params.guildId}/config/voice`, {
              voiceLogs: this.logs.voiceLogs,
              voiceLogsChannel: this.logs.voiceLogsChannel,
          }, { withCredentials: true }).then((res) => {
              this.alert.message = res.data.message;
              this.alert.type = "success";
          })
          .catch((err) => {
                this.alert.message = err.response.data.message;
                this.alert.type = "danger";
          });
      }
  },
};
</script>

<style scoped>
    .card {
        margin-top: 20px !important;
    }
    .red {
        color: #dc3545;
    }
</style>