<template>
  <b-container>
    <b-row>
      <b-col cols="12" class="mt-4">
        <b-avatar
          variant="info"
          src="https://cdn.nishikino.xyz/yukiko/static/yukiko.png"
          size="3rem"
        ></b-avatar>
        <span style="font-size: 30px">
          Yukiko <b-icon icon="patch-check"></b-icon
        ></span>
        <p>
          Yukiko is our production version, this version is the most stable and
          ready to be use anywhere. You can add via this website to any of your
          guilds worry free of any unexpected things. <br />
          <br />
          Add it to your server:
          <a
            href="https://discord.com/oauth2/authorize?client_id=641626560457342987&scope=bot"
            >click here</a
          >
          <br />
          prefix: @Yukiko#9360 or a! <br />
          State: Stable <br />
          Status: Public
        </p>
      </b-col>
      <hr />
      <b-col cols="12" class="mt-4">
        <b-avatar
          variant="info"
          src="https://cdn.nishikino.xyz/yukiko/static/yukikoCanary.png"
          size="3rem"
        ></b-avatar>
        <span style="font-size: 30px"> Yukiko Canary</span>
        <p>
          Yukiko Canary is our beta test of Yukiko, Just like Discord Canary
          this version get the latest feature that's not got pushed in
          production yet. on this version there is more chance to get unexpected
          things to happened since it's a testing version, you can add it to any
          of your guild, but know this version might be bugged nor works
          properly due to the early pushed feature we add. <br />
          <br />
          Add it to your server:
          <a
            href="https://discord.com/oauth2/authorize?client_id=772631754100047883&scope=bot"
            >click here</a
          >
          <br />
          prefix: @Yukiko#3794 or y! <br />
          State: Unstable <br />
          Status: Public
        </p>
      </b-col>
      <b-col cols="12" class="mt-4">
        <b-avatar
          variant="info"
          src="https://cdn.nishikino.xyz/yukiko/static/yukikoDev.png"
          size="3rem"
        ></b-avatar>
        <span style="font-size: 30px"> Yukiko Dev</span>
        <p>
          This is our private version of Yukiko, it's offline most of the time,
          and you cannot add it to your guild, this bot is in a bunch of private
          developement guilds only owned by the YDT developpers. this version is
          used for fresh just writen code for testing.<br />
          <br />
          Add it to your server:
          <a
            href="https://sh.nishikino.xyz/YukikoDev"
            target="_blank"
            rel="noopener noreferrer"
            >[PRIVATE]</a
          >
          <br />
          prefix: @Yukiko#3091 or ~ <br />
          State: InDev <br />
          Status: Private
        </p>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import axios from "axios";
export default {
  beforeMount() {
    axios
      .get(`${process.env.VUE_APP_URI}/api/agi/user/profile`, {
        withCredentials: true,
      })
      .then((res) => {
        const data = res.data;
        if (data.isban == true) return this.$router.push("/banned");
      });
  }
};
</script>