<template>
        <div class="container">
        <div class="row">
            <div class="header">
                <h1 class="site-title display-3">Yukiko <small>has Shut down.</small></h1>
                <h3 class="headline">2019 - 2024</h3>
            </div>
            <div class="image">
                <img src="https://cdn.yukiko.app/web/HomeYukiko.webp" height="500" class="mt-4 rounded contain">
            </div>
            <h3 class="mt-4">Thank you for using our services!</h3>
        </div>
    </div>
</template>

<script setup>
document.title = 'Yukiko - The Discord bot';
</script>

<style scoped>
.container {
    height: 100vh;
    margin: 0 auto;
    max-width: 1200px;
    text-align: center;
}

.row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.header {
    margin-top: 2rem;
}

.site-title {
    font-size: 4rem;
    font-weight: 700;
}

.headline {
    font-size: 2rem;
    font-weight: 400;
}

.image {
    margin-top: 2rem;
}
</style>