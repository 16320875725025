<template>
  <div>
    <b-container>
        <b-row>
            <b-col cols="12" class="text-center">
                <h1>Roles Settings</h1>
            </b-col>
            <!-- Start Default role. -->
            <b-col>
                <h4>{{$t('logeduser.guilds.guildDash.roles.default')}} - <i>{{$t('logeduser.guilds.guildDash.roles.soon')}} </i></h4>
                <p>
                    {{$t('logeduser.guilds.guildDash.roles.default_desc')}}  <br>
                    <small>{{$t('logeduser.guilds.guildDash.roles.default-disabled')}} </small>
                </p>
                <b-form class="disabled">
                <b-form-select vartiant="dark" v-model="selectedRole" :options="roles"></b-form-select>
                <b-button variant="outline-primary" class="mt-2" disabled>{{$t('logeduser.guilds.guildDash.utility.submit')}} </b-button>
                </b-form>
            </b-col>
            <b-col cols="12" class="mt-4">
                <p>
                    {{$t('logeduser.guilds.guildDash.roles.soon-more')}} 
                </p>
            </b-col>
            <!-- End Default Role -->
            <!-- Start Admin Role -->
            <b-col>
                <h4>Admin Role - <i>{{$t('logeduser.guilds.guildDash.roles.soon')}} </i></h4>
                <p>
                    Admin role define your Master role.<br>
                    The one role that have all permissions over Yukiko <br>
                    <small>This feature is not implemented.You soon will be able to set the role in advence.</small>
                </p>
                <b-form class="disabled">
                <b-form-select vartiant="dark" v-model="selectedModRole" :options="roles"></b-form-select>
                 <b-button variant="outline-primary" class="mt-2" disabled>{{$t('logeduser.guilds.guildDash.utility.submit')}} </b-button>
                </b-form>
            </b-col>
            <b-col cols="12" class="mt-4">
                <p>
                    {{$t('logeduser.guilds.guildDash.roles.soon-more')}} 
                </p>
            </b-col>
            <!-- END Admin Role -->
            <!-- Start Mod Role -->
            <b-col>
                <h4>Moderator Role - <i>{{$t('logeduser.guilds.guildDash.roles.soon')}} </i></h4>
                <p>
                    Moderator role define your minimun role for moderation (Kick, Ban, Mute, Warn)<br>
                    it allows you to use moderation slash commands. <br>
                    <small>This feature is not implemented.You soon will be able to set the role in advence.</small>
                </p>
                <b-form class="disabled">
                <b-form-select vartiant="dark" v-model="selectedModRole" :options="roles"></b-form-select>
                 <b-button variant="outline-primary" class="mt-2" disabled>{{$t('logeduser.guilds.guildDash.utility.submit')}} </b-button>
                </b-form>
            </b-col>
            <b-col cols="12" class="mt-4">
                <p>
                    {{$t('logeduser.guilds.guildDash.roles.soon-more')}} 
                </p>
            </b-col>
            <!-- END Mod Role -->
        </b-row>
    </b-container>
     
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      defaultRoles: "",
      roles: [],
      selectedRole: this.$route.params.guildId,
      selectedModRole: this.$route.params.guildId,
      
    };
  },
  mounted() {
    axios.get(`${process.env.VUE_APP_URI}/api/agi/dashboard/guild/${this.$route.params.guildId}/config/roles`, {withCredentials: true})
    .then(res =>{
        this.roles = res.data.GuildRole.roleList
        this.defaultRoles = res.data.defaultRole
    })
  },
};
</script>

<style scoped>
.disabled {
    cursor: not-allowed;
}
</style>